import React, { useState, useEffect } from "react"
import { Button } from "theme-ui"

const ScrollToTop = ({ showAfterHeight = 300 }) => {
  const [isVisible, setIsVisible] = useState(false)

  // Show button after scrolling a certain height
  const toggleVisibility = () => {
    if (window.scrollY > showAfterHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [])

  return (
    isVisible && (
      <Button
        onClick={scrollToTop}
        sx={{
          position: "fixed",
          bottom: "2rem",
          right: "2rem",
          backgroundColor: "primary",
          color: "white",
          borderRadius: "50%",
          width: ["60px", "80px"],
          height: ["60px", "80px"],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
        }}
        aria-label="Scroll to top"
      >
        ↑
      </Button>
    )
  )
}

export default ScrollToTop